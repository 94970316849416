<template>
    <v-container>
        <base-material-card icon="mdi-basket" title="Producto" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="6">
                            <v-select v-model="producto.familia_id" :items="familias" label="Familia" item-value="id" item-text="nombre" :error-messages="errors.familia_id" @change="loadSubfamilias(); producto.subfamilia_id = null" class="required"></v-select>
                            <v-select v-model="producto.subfamilia_id" :items="subfamilias" label="Sub-familia" item-value="id" item-text="nombre" :error-messages="errors.subfamilia_id" class="required"></v-select>
                            <v-text-field label="Nombre" v-model="producto.nombre" :error-messages="errors.nombre" class="required"></v-text-field>
                            <v-text-field label="Código" v-model="producto.codigo" :error-messages="errors.codigo" class="required"></v-text-field>
                            <v-text-field label="Código de barras" v-model="producto.codigo_barras" :error-messages="errors.codigo_barras" class="required"></v-text-field>
                            <v-select v-model="producto.impuesto_id" multiple :items="impuestos" label="Impuestos" item-value="id" item-text="nombre" :error-messages="errors.impuesto_id"></v-select>
                            <v-autocomplete v-model="producto.clave_sat" :items="sat_claves" :search-input.sync="sat_clave_search" color="white" hide-no-data hide-selected item-text="nombre" item-value="id" label="Clave SAT" return-object @change="producto.clave_sat_id = producto.clave_sat.id">
                            </v-autocomplete>

                            <v-autocomplete v-model="producto.clave_unidad_medida" :items="sat_unidades" :search-input.sync="clave_unidad_medida_search" color="white" hide-no-data hide-selected item-text="nombre" item-value="id" label="Unidad de medida" return-object @change="producto.clave_unidad_medida_id = producto.clave_unidad_medida.id">
                            </v-autocomplete>
                            <!-- <v-text-field label="Addenda" v-model="producto.addenda" :error-messages="errors.addenda"></v-text-field> -->
                        </v-col>
                        <v-col cols="6">
                            <v-textarea label="Información nutricional" v-model="producto.informacion_nutricional" :error-messages="errors.informacion_nutricional"></v-textarea>
                            <v-textarea label="Observaciones" v-model="producto.observaciones" :error-messages="errors.observaciones"></v-textarea>
                            <v-checkbox v-model="producto.comercio_exterior" label="Comercio exterior"></v-checkbox>
                            <v-text-field label="Fracción arancelaria" v-model="producto.fraccion_arancelaria" :error-messages="errors.fraccion_arancelaria" :disabled="!producto.comercio_exterior" :value="1"></v-text-field>
                            <v-text-field label="Marca" v-model="producto.marca" :error-messages="errors.marca" :disabled="!producto.comercio_exterior"></v-text-field>
                            <v-text-field label="Modelo" v-model="producto.modelo" :error-messages="errors.modelo" :disabled="!producto.comercio_exterior"></v-text-field>
                            <v-text-field label="Submodelo" v-model="producto.submodelo" :error-messages="errors.submodelo" :disabled="!producto.comercio_exterior"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-file-input label="Imagenes" v-model="imagen" accept="image/*" :error-messages="errors.imagenes" @keyup.enter="addImagen" append-outer-icon="fa fa-plus" @click:append-outer="addImagen"></v-file-input>

                        </v-col>
                        <v-col cols="6">
                            <v-file-input label="Adjuntos" v-model="adjunto" :error-messages="errors.adjuntos" @keyup.enter="addAdjunto" append-outer-icon="fa fa-plus" @click:append-outer="addAdjunto"></v-file-input>
                            <v-list subheader>
                                <v-list-item v-for="item in producto.adjuntos_files" :key="item.name">
                                    <v-list-item-icon>
                                        <v-icon>far fa-file</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.name"></v-list-item-title>
                                    </v-list-item-content>

                                    <!-- <v-list-item-action>
                                        <v-btn icon @click="">
                                            <v-icon color="grey lighten-1">mdi-information</v-icon>
                                        </v-btn>
                                    </v-list-item-action> -->
                                </v-list-item>
                                <v-list-item v-for="item in producto.adjuntos" :key="item.nombre" @click="showFile(item)">
                                    <v-list-item-icon>
                                        <v-icon :color="item.orden ? 'primary' : null">far fa-file</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.nombre"></v-list-item-title>
                                    </v-list-item-content>

                                    <!-- <v-list-item-action>
                                        <v-btn icon @click="">
                                            <v-icon color="grey lighten-1">mdi-information</v-icon>
                                        </v-btn>
                                    </v-list-item-action> -->
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-row>
                        <v-col cols="6">
                            <v-subheader>SKU</v-subheader>
                            <v-text-field label="SKU Chedraui" v-model="producto.sku_chedraui" :error-messages="errors.sku_chedraui"></v-text-field>
                            <v-text-field label="SKU City Fresko" v-model="producto.sku_cityfresko" :error-messages="errors.sku_cityfresko"></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-btn color="primary" @click="save" :loading="loading">Guardar</v-btn>
                    <v-btn @click="$router.push({name: 'Producto'})" :loading="loading">Cancelar</v-btn>
                </v-col>
            </v-row>
        </base-material-card>

        <base-material-card icon="fas fa-image" title="Galería">
            <v-row>
                <v-col cols="3" v-for="imagen in producto.imagenes" :key="imagen.id">
                    <v-card>
                        <v-img :src="imagen.url"></v-img>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn :loading="loading" @click="deleteImage(imagen)" fab small color="red">
                                <v-icon small>far fa-trash-alt</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>
    const MODEL = {
        nombre_comercial: null,
        rfc: null,
        razon_social: null,
        archivo_key_file: null,
        archivo_cer_file: null,
        password_csd: null,
        logo: null,
        calle: null,
        num_exterior: null,
        num_interior: null,
        colonia: null,
        localidad: null,
        municipio: null,
    }
    export default {
        data: () => ({
            cuentas_headers: [
                { text: 'Banco', value: 'banco', sortable: false },
                { text: 'RFC', value: 'rfc', sortable: false },
                { text: 'Número', value: 'numero', sortable: false },
                { text: 'Acciones', value: 'accciones', sortable: false },
            ],
            familias: [],
            subfamilias: [],
            sat_unidades: [],
            sat_claves: [],
            impuestos: [],
            sat_clave_search: null,
            clave_unidad_medida_search: null,
            producto: {
                familia_id: null,
                subfamilia_id: null,
                comercio_exterior: null,
                imagenes_files: [],
                adjuntos_files: []
            },
            imagen: null,
            adjunto: null,
            errors: {},
            loading: false
        }),
        mounted() {
            this.loadFamilias()
            this.loadSatClaves()
            this.loadSatUnidades()
            this.loadImpuestos()
            if (this.$route.params.id) {
                this.load()
            }
        },
        methods: {
            loadImpuestos() {
                this.$http.get('/impuestos/all').then(response => {
                    this.impuestos = response.data
                })

            },
            loadSatClaves() {
                this.$http.get('/sat/claves/all').then(response => {
                    this.sat_claves = response.data.map(unidad => {
                        return {
                            id: unidad.id,
                            nombre: `${unidad.cclaveprodserv} - ${unidad.descripcion}`
                        }
                    })
                })
            },
            loadSatUnidades() {
                this.$http.get('/sat/unidades/all').then(response => {
                    this.sat_unidades = response.data.map(unidad => {
                        return {
                            id: unidad.id,
                            nombre: `${unidad.cclaveunidad} - ${unidad.nombre}`
                        }
                    })
                })
            },
            loadFamilias() {
                this.$http.get('/familias/all').then(response => {
                    this.familias = response.data
                })
            },
            loadSubfamilias() {
                this.$http.get(`/familias/${this.producto.familia_id}/subfamilias`).then(response => {
                    this.subfamilias = response.data
                })
            },
            load() {
                this.$http.get(`/productos/${this.$route.params.id}`).then(response => {
                    response.data.impuesto_id = response.data.impuestos.map(impuesto => impuesto.id)
                    this.producto = response.data
                    this.producto.imagenes_files = []
                    this.producto.adjuntos_files = []
                    this.loadSubfamilias()
                })
            },
            save() {
                this.errors = {}
                this.loading = true
                if (this.producto.id) {
                    this.update()
                    return
                }
                this.create()
            },
            create() {
                this.$http.post('/productos', this.toFormData(this.producto)).then(response => {
                    this.$router.push({ name: 'Producto' })
                }).catch(error => {
                    console.log(error)
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            update() {
                this.$http.post(`/productos/${this.producto.id}`, this.toFormData(this.producto, 'PUT')).then(response => {
                    this.$router.push({ name: 'Producto' })
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            addImagen() {
                if (this.imagen) {
                    this.producto.imagenes_files.push(this.imagen)
                    this.imagen = null
                }
            },
            addAdjunto() {
                if (this.adjunto) {
                    this.producto.adjuntos_files.push(this.adjunto)
                    this.adjunto = null
                }
            },
            showFile(item) {
                if (item.url) {
                    window.open(item.url)
                }
            },
            toFormData(object, method) {
                let formData = new FormData

                if (method) {
                    formData.append('_method', method)
                }

                for (const key in object) {
                    if (object.hasOwnProperty(key)) {
                        const element = object[key];
                    }
                    if (object[key]) {
                        if (Array.isArray(object[key])) {
                            object[key].forEach(subobject => {
                                formData.append(`${key}[]`, subobject)
                            })
                        } else {
                            formData.append(key, object[key])
                        }
                    }
                }
                return formData
            },
            deleteImage(imagen) {
                this.loading = true
                this.$http.delete(`/imagenes/${imagen.id}`).then(response => {

                }).finally(() => {
                    this.loading = false
                    this.load()
                })
            },
            base64Image(image) {
                console.log(image)
                let reader = new FileReader
                reader.readAsDataURL(image)
                reader.addEventListener('load', () => {
                    return reader.result
                })
            }
        }
    }
</script>